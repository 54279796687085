import { mapMutations, mapState } from 'vuex'
import KnTable from '../../../shared/components/KnTable.vue'
import { getItem } from "@/api/api-methods";
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

export default {
  components: { KnTable },
  mixins: [paginationMixin],
  data() {
    return {
      groups: [],
      products: [],
      headers: [
        { text: 'Número de orden', value: 'numero_orden', class: 'purple--text' },
        { text: 'Estatus', value: 'estatus_orden.dato', class: 'purple--text' },
        { text: 'Cliente', value: 'cliente', class: 'purple--text' },
        { text: 'Peso volumétrico', value: 'peso_volumetrico', class: 'purple--text' },
        { text: 'Subtotal', value: 'sub_total', class: 'purple--text' },
        { text: 'Impuestos', value: 'total_impuestos', class: 'purple--text' },
        { text: 'Total', value: 'total_orden', class: 'purple--text' },
        { text: '', value: 'acciones', class: 'purple--text' },
      ],
      items: [],
      orders: [],
      loading: false,
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'warehouse', 'search'])
  },
  watch: {
    search: {
      async handler() {
        if (this.loading) return
        await this.delay(500);
        await this.getOrders();
      },
    },
  },
  async created() {
    this.setIsLogin(false)
    await this.getOrders(this.institutionId)
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    // openConf(id) {
    //   // console.log(id);
    // },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    fullName({
      primer_nombre: pn,
      segundo_nombre: sn,
      apellido_paterno: ap,
      apellido_materno: am
    }) {
      return [pn, sn, ap, am].filter(val => val !== null && val !== undefined).join(' ');
    },
    async getOrders() {
      try {
        this.loading = true
        const limitFilter = this.pagination.limit !== null ? `&limit=${this.pagination.limit}` : '';
        const offsetFilter = this.pagination.offset !== 0 ? `&offset=${this.pagination.offset}` : '';
        const searchFilter = this.search !== null ? `&numero_orden=${this.search}` : '';

        const url = `/app-ordenes/filters/orden?&institucion_educativa=${this.institutionId}&estatus_sistema=true${offsetFilter}${limitFilter}${searchFilter}`;
        const responseData = await getItem(url);
        const personalInfoPromises = responseData.results.map(order => getItem(`/app-personas/datos-personales/${order.alumno.datos_personales}`));
        const personalInfoArray = await Promise.all(personalInfoPromises);
        this.items = responseData.results;
        this.items.map((order, index) => {
          const personalInfo = personalInfoArray[index];
          const { primer_nombre, segundo_nombre, apellido_paterno, apellido_materno } = personalInfo;

          const fullName = this.fullName({ primer_nombre, segundo_nombre, apellido_paterno, apellido_materno });
          order.cliente = fullName
        });
        this.setPaginationCount(responseData.count);
      } catch (error) {
        console.error('Error al obtener ordenes:', error);
      } finally {
        this.loading = false
      }
    },
    async changePageOrders(page = 1) {
      this.setPaginationPage(page);
      await this.getOrders();
    }
  }

}